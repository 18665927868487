import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

@Component({
  selector: 'acc-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxListComponent implements OnChanges {
  @Input()
  public items: DictionaryItem[] = [];

  @Input()
  public itemsTooltips: Map<number | string, string> = new Map<number, string>();

  @Input()
  public hideAllCheckbox = false;

  @Input()
  public isSelectionDisabled = false;

  @Input()
  public selectedItems: DictionaryItem[] = [];

  @Input()
  public maximumNumberOfItemsToSelect: number = null;

  @Input()
  public maximumNumberOfItemsToSelectTooltip: string = null;

  @Input()
  public itemClass = 'acc-checkbox--secondary';

  @Output()
  public changeEvent = new EventEmitter<DictionaryItem[]>();

  public isAllItemSelected: boolean;
  public isNumberOfSelectedItemsExceedMaximumNumberOfItemsToSelect = false;

  public ngOnChanges(): void {
    this.checkIsAllItemSelected();
  }

  public trackById(index: number, item: DictionaryItem): number {
    return item.id;
  }

  public checkIsAllItemSelected(): void {
    const itemIds: number[] = this.getItemIds();
    const selectedItemIds: number[] = this.getSelectedItemIds();

    this.isAllItemSelected = itemIds.every((id: number) => selectedItemIds.includes(id));

    this.checkIsNumberOfSelectedItemsExceedMaximumNumberOfItemsToSelect(selectedItemIds.length);
  }

  private getSelectedItemIds(): number[] {
    return this.selectedItems.map((item: DictionaryItem) => item.id);
  }

  private checkIsNumberOfSelectedItemsExceedMaximumNumberOfItemsToSelect(selectedItemsCount: number): void {
    this.isNumberOfSelectedItemsExceedMaximumNumberOfItemsToSelect = !!this.maximumNumberOfItemsToSelect &&
      selectedItemsCount >= this.maximumNumberOfItemsToSelect;
  }

  public isIndeterminateState(): boolean {
    return this.isAnyItemSelected() && !this.isAllItemSelected;
  }

  private isAnyItemSelected(): boolean {
    const selectedItemIds: number[] = this.getSelectedItemIds();
    const itemIds: number[] = this.getItemIds();

    return itemIds.some((itemId: number) => selectedItemIds.includes(itemId));
  }

  public isItemSelected(item: DictionaryItem): boolean {
    return this.selectedItems.some(({ id }: DictionaryItem) => id === item.id);
  }

  public onAllItemsChange(change: MatCheckboxChange): void {
    this.selectedItems = change.checked ? [...this.selectedItems, ...this.getItemsNotSelectedYet()] : [...this.removeItemsFromSelected()];

    this.sortSelectedItems();
    this.changeEvent.emit(this.selectedItems);
    this.checkIsAllItemSelected();
  }

  private getItemsNotSelectedYet(): DictionaryItem[] {
    const selectedItemIds: number[] = this.getSelectedItemIds();

    return this.items.filter((item: DictionaryItem) => !selectedItemIds.includes(item.id));
  }

  private removeItemsFromSelected(): DictionaryItem[] {
    const itemIds: number[] = this.getItemIds();

    return this.selectedItems.filter((item: DictionaryItem) => !itemIds.includes(item.id));
  }

  private getItemIds(): number[] {
    return this.items.map((item: DictionaryItem) => item.id);
  }

  public onItemChange(change: MatCheckboxChange, item: DictionaryItem): void {
    this.selectedItems = change.checked
      ? [...this.selectedItems, item]
      : this.selectedItems.filter(({ id }: DictionaryItem) => id !== item.id);

    this.sortSelectedItems();
    this.changeEvent.emit(this.selectedItems);
    this.checkIsAllItemSelected();
  }

  private sortSelectedItems(): void {
    this.selectedItems.sort((item1: DictionaryItem, item2: DictionaryItem) =>
      (item1.id - item2.id)
    );
  }
}
